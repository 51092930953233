<template>
  <div style=";color: #000;height: 100%;">
    <!-- <van-nav-bar title="会员升级" :z-index="999" :fixed="true" left-arrow @click-left="back" /> -->
    <!-- <div style="padding:46px 0px;"> -->
    <div class="toimg" v-show="!pleshow">
      <img class="imgs" src="../../assets/newimg/dinghuodong.png" alt />

      <div class="dingwei">
        <div class="heng" v-if="this.$route.query.cid">
          <div class="left">
            <span>联系人:</span>
            <span>{{namesform.username}}</span>
          </div>
          <div class="left">
            <span>TEL:</span>
            <span>{{namesform.phone}}</span>
          </div>
        </div>
        <div v-else class="hengss"></div>
        <div class="neirong">
          <span class="che">车牌号码:</span>
          <input
            class="inps"
            v-model="carNo"
            @click="getple"
            clearable
            required
            readonly
            placeholder="请输入"
            type="text"
          />
        </div>
        <div class="neirong">
          <span class="che">手机号码:</span>
          <input class="inps" v-model="phone" required clearable type="tel" placeholder="请输入手机号码" />
        </div>
      </div>
    </div>
    <!-- <div v-show="!pleshow" style="position: relative;">
      <img class="imgs" src="../../assets/newimg/huodong.png" alt />
      <span class="xiangqin" @click="tojiu(4)">点击查看详情</span>
    </div> -->
    <img class="imgs" v-show="!pleshow" src="../../assets/newimg/huodong2.png" alt />

    <!-- <div class="foot" v-show="!pleshow">
      <div class="footbei">
        <div class="jifen">
          <img class="huo" src="../../assets/img/huo.png" alt />
          <span style="vertical-align: middle;">
            截止目前已有
            <span style="font-size: 18px;color: red;">{{total}}</span> 人购买
          </span>
          <img class="huo" src="../../assets/img/huo.png" alt />
        </div>
        <div class="jifen_a">
          <van-row class="jifen_b" @click="goTop">
            <van-col span="3">序号</van-col>
            <van-col span="6">车牌号</van-col>
            <van-col span="7">手机号码</van-col>
            <van-col span="8" style="border-right: none">购买时间</van-col>
          </van-row>
          <van-row class="jifen_c" v-for="(item, idx) in joinList" :key="idx">
            <van-col span="3">{{total-idx}}</van-col>
            <van-col span="6">{{item.carNo}}</van-col>
            <van-col span="7">{{item.phone}}</van-col>
            <van-col span="8">{{item.buyDate?item.buyDate.substr(5,11):''}}</van-col>
          </van-row>
          <van-row class="jifen_d" @click="chakangenduo" v-if="!finished">
            <van-col span="24">
              <span style="vertical-align: middle">查看更多</span>
              <van-icon style="vertical-align: middle" name="arrow-down" />
            </van-col>
          </van-row>
        </div>
      </div>
    </div> -->
    <div style="height: 50px;" v-show="!pleshow"></div>
    <!-- <div class="bott">
      <div class="left boda">
        <div class="left activefener">500</div>
        <div class="right">
          <van-count-down millisecond :time="day" format="HH:mm:ss:SS">
            <template #default="timeData">
              <span class="jiandaot" v-if="day > 86400000">
                <span style="font-size: 18px;margin-right: 1px;">{{ timeData.days }}</span>
                <span>天</span>
              </span>
              <span class="jiandao">{{ timeData.hours }}</span>
              <span class="colon">:</span>
              <span class="jiandao">{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="jiandao">{{ timeData.seconds }}</span>
              <span class="colon">.</span>
              <span class="jiandao">{{ timeData.milliseconds}}</span>
            </template>
          </van-count-down>
        </div>
      </div>
      <div class="lb_tijiao" v-if="openCombo == 0 " @click="goumai" v-preventReClick>
        <img class="ddhh" src="../../assets/img/goumai.png" alt />

        <span style="vertical-align: middle;">{{day > 0?'立即购买':'活动已结束'}}</span>
      </div>
      <div class="lb_tijiao" v-else>
        <img class="ddhh" src="../../assets/img/goumai.png" alt />
        <span style="vertical-align: middle;">您已购买</span>
      </div>
    </div> -->
     <div class="bott">
        <van-button type="warning" v-if="openCombo == 0 " @click="goumai" v-preventReClick>立 即 抢 购</van-button>
         <van-button type="warning" v-else  v-preventReClick>您 已 购 买</van-button>
     </div>
    <carPlate ref="ple" v-show="pleshow" :plate_number="carNo" @getPlate="getPlate"></carPlate>
  </div>
</template>
<script>
import orderModel from "../../api/order";
import userModel from "../../api/user";
import {
  checkopenCombo,
  activityreport,
  checkteam,
  sellManinfo
} from "../../api/check";
import carPlate from "../../components/carplate.vue";
import preventReClick from "../../assets/preventReClick";
export default {
  components: {
    carPlate
  },
  data() {
    return {
      isWxMini: "",
      total: "",
      joinList: [],
      list: {},
      openCombo: 0,
      finished: false,
      pinzhenisok: true,
      tuanisok: false,
      namesform: {},
      day: 0,
      total: 0,
      page: 0,
      size: 50,
      carNo: "",
      showisok: null,
      phone: "",
      pleshow: false,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      chexin: JSON.parse(sessionStorage.getItem("chexin"))
    };
  },
  methods: {
    nameform() {
      sellManinfo({ uid: this.$route.query.cid }).then(e => {
        if (e.code == 200) {
          this.namesform = e.data;
          console.log(e);
        }
      });
    },
    times() {
      var _this = this;
      var shijian = "2022-01-31 00:00:00";
      var jieshu = new Date(shijian);
      jieshu = new Date(jieshu.setDate(jieshu.getDate() + 1));
      jieshu =
        jieshu.getFullYear() +
        "-" +
        (jieshu.getMonth() + 1 > 9
          ? jieshu.getMonth() + 1
          : "0" + (jieshu.getMonth() + 1)) +
        "-" +
        (jieshu.getDate() > 9 ? jieshu.getDate() : "0" + jieshu.getDate());

      var date = new Date().getTime();
      var time1 = shijian.replace(/-/g, "/");
      var until = new Date(time1).getTime();

      var days = until - date;
      // 下面都是简单的数学计算
      _this.day = days + 86399000;
    },
    goTop() {
      cancelAnimationFrame(this.timer);
      const self = this;
      self.timer = requestAnimationFrame(function fn() {
        const oTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop - 50;
          self.timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(self.timer);
        }
      });
    },
    namelist() {
      checkopenCombo().then(e => {
        if (e.code == 200) {
          if (e.data.phone == null || e.data.phone == "null") {
            this.showisok = true;
          }
          console.log(this.showisok);
          this.carNo = e.data.carNo;
          this.phone = e.data.phone;
          this.openCombo = e.data.openCombo;
        }
      });
    },
    chakangenduo() {
      this.page++;
      this.canyulist();
    },
    canyulist() {
      activityreport({ page: this.page, size: this.size }).then(res => {
        console.log(res);
        if (res.code == 200) {
          let rows = res.data.content;

          this.total = res.data.totalElements;
          if (rows.length > 0) {
            rows.forEach((item, index) => {
              if (item.carNo) {
                item.carNo =
                  item.carNo.substr(0, 3) + "**" + item.carNo.substr(5);
              }
              if (item.phone) {
                item.phone =
                  item.phone.substr(0, 3) +
                  "**" +
                  item.phone.substring(item.phone.length - 4);
              }
              if (item.payDate) {
                item.payDate = item.payDate.substr(-14);
                item.payDate = item.payDate.substring(0, 11);
              }
            });
          }
          this.joinList = this.joinList.concat(rows);
          if (this.joinList.length >= this.total) {
            this.finished = true;
          }
        }
      });
    },
    //打开键盘
    getple() {
      this.pleshow = true;
      this.$refs.ple.clickShowKeyboard();
    },
    getPlate(val) {
      console.log(val);
      this.carNo = val;
      this.pleshow = false;
    },
    timeVip() {
      var _this = this;
      checkteam({ uid: this.userInfo.id }).then(e => {
        if (e.code == 200) {
          this.tuanisok = e.data;
          this.$nextTick(() => {
            _this.getConfig();
          });
          console.log(this.tuanisok);
        }
      });
    },
    tojiu(type) {
      this.$router.push({
        path: "jiuimg",
        query: {
          type: type,
        }
      });
    },
    goumai() {
      var _this = this;
      this.goTop();
      console.log(this.showisok);
      if (_this.carNo == "" || _this.carNo == null) {
        _this.$toast("请填写车牌号码");
        return;
      } else if (_this.phone == "" || !_this.phone) {
        _this.$toast("请输入手机号码");
        return;
      } else if (!/^1[3456789]\d{9}$/.test(_this.phone)) {
        _this.$toast("手机号码格式错误");
        return;
      }
      if (this.showisok == true) {
        // const loading = this.$toast.loading({
        //   mask: true,
        //   duration: 0,
        //   message: "加载中..."
        // });
        const data = {
          carNo: this.carNo,
          gid: this.shangjia.id,
          mallUserId: this.userInfo.id,
          phone: this.phone,
          haveChange: 1,
          haveAdopt: 1,
          onDefault: true //是否默认车辆
        };

        userModel.carInfochexin(data).then(e => {
          // loading.clear();
          if (e.code == 200) {
            _this.zhifu();
          }
        });
        return;
      } else {
        _this.zhifu();
      }
    },
    zhifu() {
      var _this = this;

      var province = this.shangjia.province
        ? this.shangjia.province.toString()
        : "";
      var city = this.shangjia.city ? this.shangjia.city.toString() : "";
      var district = this.shangjia.district
        ? this.shangjia.district.toString()
        : "";
      var address = this.shangjia.address
        ? this.shangjia.address.toString()
        : "";
      var areaDetail = province + city + district + address;
      var lis = {
        id: 70,
        activityPrice: 980,
        // activityPrice: 0.01,
        separateId: 0, //消费合伙人传1,88礼包穿2，399套餐传3，999套餐传4

        customerId: this.userInfo ? this.userInfo.customerId : "",
        gid: this.shangjia.id,
        payType: 1,
        type: 16,
        // shareId: this.shareId ? this.shareId : null,
        name: "周年庆典VIP客户钜惠",
        areaDetail: areaDetail, //维修厂详细地址
        carModel: this.chexin ? this.chexin.carBrand : "", //车型
        carNo: this.chexin ? this.chexin.carNo : this.carNo, //车牌
        garageName: this.shangjia.name, //修理厂名称
        positionJd: this.shangjia.positionJd,
        shareId: this.$route.query.cid ? this.$route.query.cid : 0, //分享者的id
        shareFlag: 1, //分享提成标志 0:不是 1:是
        positionWd: this.shangjia.positionWd,
        tel: this.shangjia.legalPersonPhone,
        receiver: this.shangjia.legalPerson //用户名称
      };
      var isWxMini = window.__wxjs_environment === "miniprogram";
      console.log(isWxMini);
      if (isWxMini) {
        lis.token = localStorage.getItem("Authorization");
        lis.ccisok = 4; //3:会员卡订单 4:套餐订单 5:充值卡订单
        let payDataStr = JSON.stringify(lis);
        wx.miniProgram.navigateTo({
          url: "/pages/pay/pay?payDataStr=" + payDataStr // 微信小程序调用支付的页面
        });
      } else {
        orderModel.goumaivip(lis).then(res => {
          if (res.code == 200) {
            if (typeof WeixinJSBridge === "undefined") {
              // 微信浏览器内置对象。参考微信官方文档
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
              }
            } else {
              console.log("准备调用微信支付");
              _this.onBridgeReady(res.data);
            }
          }
        });
      }
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast.success("支付成功");
            setTimeout(() => {
              window.location.href =
                "/order_details?id=" +
                data.id +
                "&status=" +
                3 +
                "&type=" +
                4 +
                "&rowtype=" +
                0 +
                "&isok=" +
                true;
              //   vm.$router.go(-1);
            }, 1000);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    back() {
      this.$router.go(-1);
    },
    getConfig() {
      let that = this;
      userModel
        .config({
          url: location.href.split("#")[0],
          gid: sessionStorage.getItem("Agid")
            ? sessionStorage.getItem("Agid")
            : 208
        })
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["onMenuShareAppMessage"]
            });
            console.log(this.tuanisok);
            if (this.tuanisok == true) {
              console.log("asdad");
              var link =
                "https://shopping.car-posthouse.cn/start_business?gid=" +
                484 +
                "&cid=" +
                this.userInfo.id +
                "&appid=" +
                "wx406b62706ded5aa8" +
                "&denglu=" +
                true;
            } else {
              var link =
                "https://shopping.car-posthouse.cn/start_business?gid=" +
                484 +
                "&cid=" +
                this.$route.query.cid +
                "&appid=" +
                "wx406b62706ded5aa8" +
                "&denglu=" +
                true;
            }
            // if (this.$route.query.cid != undefined) {
            //   var link =
            //     "https://shopping.car-posthouse.cn/start_business?gid=" +
            //     sessionStorage.getItem("gid") +
            //     "&cid=" +
            //     this.$route.query.cid +
            //     "&appid=" +
            //     sessionStorage.getItem("appid") +
            //     "&denglu=" +
            //     true;
            // } else {
            //   var link =
            //     "https://shopping.car-posthouse.cn/start_business?gid=" +
            //     sessionStorage.getItem("gid") +
            //     "&cid=" +
            //     this.userInfo.id +
            //     "&appid=" +
            //     sessionStorage.getItem("appid") +
            //     "&denglu=" +
            //     true;
            // }
            var desc =
              that.userInfo.realName + "已参与活动，邀你一起参与共享优惠";
            wx.ready(() => {
              wx.onMenuShareAppMessage({
                title:
                  "东莞黄江驿车驹·宏开店会员活动进行中，尊享4大优惠等超级VIP权益",
                desc: desc,
                link: link,
                imgUrl:
                  "http://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/test/3494/163877524258233.png?Expires=1954135242&OSSAccessKeyId=LTAI4FhBMt2eaMxuMMSCECEm&Signature=PwQzEWBL5mt69kChKWmzeVhAFf8%3D",
                success: function() {}
              });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    }
  },
  created() {
    var _this = this;
    // this.onLoad();
    if (localStorage.getItem("Authorization") == null) {
      // if (this.$route.query.denglu != undefined) {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid +
        "&cid=" +
        this.$route.query.cid;
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      sessionStorage.setItem("cid", this.$route.query.cid);
      userModel
        .getGarageInfo({ gid: this.$route.query.gid })
        .then(e => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
      return;
    } else {
    }
    var isok = localStorage.getItem("isok");
    if (isok == "true") {
      setTimeout(res => {
        this.$router.go(0);
      }, 100);
      localStorage.removeItem("isok");
    }
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
    userModel.getGarageInfo({ gid: this.$route.query.gid }).then(e => {
      // loading.clear();
      this.shangjia = e.data;
      sessionStorage.setItem("appid", e.data.idd);
      sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
      this.timeVip();
      this.namelist();
      if (this.$route.query.cid) {
        this.nameform();
      }
      // this.canyulist();
      this.times();
    });
  }
};
</script>
<style lang="less" scoped>
.heng {
  width: 90%;
  height: 31px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  margin: auto;
  font-size: 13px;
  color: #fff;
  display: flex;
  align-items: center;
}
.hengss {
  height: 31px;
}
.heng span {
  margin-right: 6px;
}
.heng div {
  width: 50%;
  text-align: center;
  
}
.imgs {
  width: 100%;
  display: block;
  // margin-top: 5px;
}
.xiangqin{
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 13px;
  color: #fff200;
  margin-bottom: 14px;
  margin-right: 50px;
}
.imgd {
  margin: 0 12px;
  margin-top: 18px;
}
.bot {
  box-shadow: 0 3px 5px 0 rgba(138, 0, 0, 0.4);
  width: 100%;
  height: 48px;
  font-family: HYZhuZiMeiXinTiW;
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0;
}
.dingwei {
  width: 100%;
  position: absolute;
  height: 53px;

  bottom: 0;
  margin-bottom: 111px;
}
.neirong {
  color: red;
  font-size: 17px;
  width: 290px;
  margin: auto;
  margin-top: 25px;
}
.che {
  font-weight: bold;
}
.inps {
  border: 1px solid red;
  margin-left: 5px;
  width: 190px;
  height: 30px;
  color: #000;
  padding: 0 5px;
}
.anniu {
  display: block;

  left: 50%;
  margin-left: -107px;
  width: 215px;
  margin: auto;
}
.anniu:active {
  margin-bottom: 7px;
}
.toimg {
  position: relative;
}
.foot {
  background: #000;
  overflow: hidden;

  // border-radius: 8px;
  overflow: hidden;
  // margin: 0 12px;
  font-size: 14px;
  color: #3c3535;
  letter-spacing: 0;
  font-family: PingFangSC-Medium;
  padding-bottom: 12px;
}
.footbei {
  background: #fff;
  width: 95%;
  margin: auto;
  padding: 10px;
  border-radius: 6px;
}
.huo {
  width: 15px;
  vertical-align: middle;
}
.jifen {
  font-size: 14px;
  font-weight: bold;
  color: #3c3535;
  line-height: 30px;
  text-align: center;
  background: #f9cc33;
  border-radius: 6px 6px 0 0;
  // margin: 12px 12px 0;
}

.jifen_a {
  font-size: 13px;
  text-align: center;

  border: 1px solid #f5f5f5;
  // border-top: none;
}
.jifen_c .van-col {
  color: #999;
  border-right: 1px solid #f5f5f5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jifen_d .van-col {
  background: #dcdcdc;
  border-radius: 4px;
  line-height: 28px;
}
.jifen_d {
  height: 28px;
  color: #666;
}
.jifen_c {
  height: 24px;
  border-bottom: 1px solid #f5f5f5;
  overflow: hidden;
}
.jifen_a .jifen_c:last-child {
  border-bottom: none;
}
.jifen_b .van-col {
  border-right: 1px solid #f5f5f5;
  text-align: center;
}
.jifen_b {
  background: #fff7dd;
  // border-bottom: 1px solid #dcdcdc;
  // border-right: 1px solid #dcdcdc;
  height: 24px;
  overflow: hidden;
}
.bott {
  width: 100%;
  position: fixed;
  color: #fff;
  // text-align: center;
  overflow: hidden;
  z-index: 99;
  bottom: 0;
  line-height: 50px;
  .van-button{
    width: 100%;
    display: block;
    height: 50px;
    font-size: 16px;
    background: #f58220;
  }
}
.boda {
  width: 50%;
  background: url(../../assets/img/daoji.png) no-repeat;
  background-size: 100% 100%;
  // background: #f9cc33;
  height: 52px;
}
.fener {
  color: #fff200;
  font-weight: bold;
  width: 46px;
  text-align: center;
  font-size: 18px;
  margin-left: 4px;
  margin-top: 10px;
}

.activefener {
  color: #fff200;
  font-weight: bold;
  width: 46px;
  text-align: center;
  font-size: 22px;
  margin-left: 2px;
  margin-top: 8px;
}
.van-count-down {
  margin-top: 25px;
  color: #fff;
  line-height: 22px;
  // font-weight: bold;
  font-size: 16px;
  margin-right: 5px;
}
.jiandaot {
  float: left;
  overflow: hidden;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  color: #f82785;
  font-weight: bold;
  margin-right: 2px;
}
.jiandao {
  float: left;
  overflow: hidden;
  // display: inline-block;
  background: #f82785;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  // padding: 0px 3px;
  width: 16px;
  text-align: center;
}
.colon {
  float: left;
  // display: inline-block;
  line-height: 20px;
  margin: 0 1px;
  color: #f82785;
}
// .bott span {
//   color: #fff;
//   font-family: PingFangSC-Medium;
//   font-size: 18px;
//   letter-spacing: 0;
//   text-align: center;
// }
.ddhh {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  // background: #fff;
  vertical-align: middle;
}
.lb_tijiao {
  width: 50%;
  float: left;
  background: #ff0e01;
  height: 52px;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
</style>